import { TrackedLink } from '@flock/shared-ui'
import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { FLOCK_FEE_COLLATERAL } from '../../../constants'
import { InvestMissionSectionPresentationalProps } from './investMissionSectionTypes'

const useInvestMissionSection: () => InvestMissionSectionPresentationalProps =
  () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

    const onSubmitAddressClick = (question: string) => {
      window.open(`${window.origin}/onboarding?utmSource=${question}`, '_blank')
    }

    const onFeeDocumentClick = () => {
      window.open(FLOCK_FEE_COLLATERAL, '_blank')
    }

    const offerQuestions = [
      {
        title: 'How does Flock Homes operate the homes in the fund?',
        text: 'Flock Homes operates all homes in the fund as long-term rentals. We have a local team in every market where we own homes, complemented by third-party property managers that we actively manage. We believe local, responsive, and knowledgeable property management delivers the best experience for residents, the community, and ultimately, the fund clients.',
      },
      {
        title: 'How does Flock Homes determine the value of the fund?',
        text: 'The fund’s value is refreshed every quarter using a combination of automated valuation models (AVMs) and third-party appraisers. Every home in the fund is required to be appraised by a third party appraiser once every 24 months.',
      },
      {
        title: 'What returns should I expect from joining Flock?',
        text: 'Flock’s fund targets an 8-12% internal rate of return to its investors based on the performance of the portfolio of homes in Flock’s fund. This return encompasses 1) income generated by the portfolio’s rental activity and 2) appreciation of your equity based on the price performance of the portfolio’s real estate. For many Flock clients, the peace of mind and countless hours they reclaim is an invaluable benefit.',
      },
      {
        title: 'What fees does Flock have?',
        text: (
          <>
            Flock Homes charges investors two simple fees: a one-time onboarding
            fee of 6% and an ongoing annual management fee of 1%. The onboarding
            fee is deducted from the value of your received equity in Flock’s
            fund, and the ongoing management fee is collected from the net
            operating income of the fund. Investors typically do not need to
            separately provide cash to transact or own with Flock. Unlike many
            other funds, Flock does not charge a performance fee. Read our{' '}
            <TrackedLink onClick={onFeeDocumentClick}>Fee Document</TrackedLink>{' '}
            for more information on our fees.
          </>
        ),
      },
      {
        title:
          'What access do I have to the portfolio and my financial performance?',
        text: (
          <>
            Upon joining Flock’s fund, you will gain access to your personalized
            online Client Portal, where you will have full detailed reporting on
            your financial performance. You also gain full visibility into the
            fund’s financials and real-time asset-level activity, such as
            individual leasing, renovations, and more. To preview the portal,{' '}
            <TrackedLink
              onClick={() =>
                onSubmitAddressClick('invest-with-flock-performance')
              }
            >
              click here to submit your rental address for an evaluation
            </TrackedLink>{' '}
            and one of Flock&apos;s Real Estate Advisors can provide you with a
            complimentary demo.
          </>
        ),
      },
    ]

    return {
      isMobile,
      isTablet,

      onSubmitAddressClick,
      offerQuestions,
    }
  }

export default useInvestMissionSection
